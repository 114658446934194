import { render, staticRenderFns } from "./orderBankDetails.vue?vue&type=template&id=797f7cde&scoped=true&"
import script from "./orderBankDetails.vue?vue&type=script&lang=js&"
export * from "./orderBankDetails.vue?vue&type=script&lang=js&"
import style0 from "./orderBankDetails.vue?vue&type=style&index=0&id=797f7cde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "797f7cde",
  null
  
)

export default component.exports