<template>
  <b-modal
    @show="initInstitutions"
    id="purchase"
    body-class="p-1"
    size="lg"
    hide-header
    v-model="show"
    title="Purchase Filters"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-card>
      <div class="modal-content">
        <b-card-header header-class="vendor-card">
          <h5>
            <b>Order Financial Institution Report</b>
          </h5>
        </b-card-header>
        <b-card-body class="order-institute-card">
          <b-list-group
            v-if="summaryStage"
            class="no-border"
            :class="{ loading: this.loading }"
          >
            <div>
              Use the search tool below to select financial institutions. Search
              by Name, City, State, Federal Reserve ID, Regulatory ID or ABA
              number.
            </div>
            <div class="flex-row align-items-top pt-1">
              <div class="container">
                <b-row class="justify-content-center list-group-container">
                  <b-col md="12" class="no-padding">
                    <div class="row">
                      <div class="col-sm-6 pr-0">
                        <b-form-group>
                          <label for="Search" class="mb-1">
                            <b>Search</b>
                          </label>
                          <b-form-input
                            type="text"
                            ref="searchInstitution"
                            class="form-control rounded"
                            placeholder="FI Name, Federal Reserve ID, Regulatory ID or ABA"
                            v-model="searchTextField"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-sm-3">
                        <b-form-group>
                          <label for="City" class="mb-1">
                            <b>City</b>
                          </label>
                          <b-form-input
                            type="text"
                            id="city"
                            class="form-control rounded"
                            placeholder="Enter City (optional)"
                            v-model="searchCityField"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-sm-3 pl-0">
                        <b-form-group>
                          <label for="State" class="mb-1">
                            <b>State</b>
                          </label>
                          <b-form-select
                            v-model="searchState"
                            @input="searchInstitutions"
                          >
                            <template slot="first">
                              <option :value="null">State (optional)</option>
                            </template>
                            <option
                              :value="state.code"
                              v-for="(state, index) in states"
                              :key="index"
                              >{{ state.name }}</option
                            >
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="full-border">
                      <v-wait for="fetchingFIs">
                        <template slot="waiting">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="7"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </template>
                        <div
                          class="filter-result"
                          v-on:scroll.passive="fetchMoreData($event)"
                        >
                          <b-card class="text-center">
                            <b-row>
                              <b-col md="12">
                                <div>
                                  <b-card class="text-left">
                                    <b-list-group
                                      v-if="emptyInstitutions"
                                      class="text-center"
                                    >
                                      <b-list-group-item>
                                        <i>No Institutions found ...</i>
                                      </b-list-group-item>
                                    </b-list-group>
                                    <b-list-group v-else>
                                      <div
                                        v-for="institution in institutions"
                                        :key="institution.id"
                                      >
                                        <b-list-group-item
                                          @click="setInstitution(institution)"
                                          :class="{
                                            selected:
                                              institution.id ===
                                              selectedInstitution.id
                                          }"
                                          :disabled="loading"
                                          :title="institution.alternate_names"
                                          >{{ institution.full_name }}&#44;
                                          {{ institution.city }}&#44;
                                          {{ institution.state }}
                                          {{
                                            '[' + institution.id + ']'
                                          }}</b-list-group-item
                                        >
                                      </div>
                                    </b-list-group>
                                  </b-card>
                                </div>
                              </b-col>
                            </b-row>
                          </b-card>
                        </div>
                      </v-wait>
                    </div>
                    <div v-if="selectedInstitutions.length > 0">
                      <div class="card-sub-header">Current Order</div>
                      <div class="selected-result">
                        <b-card class="text-center">
                          <b-row>
                            <b-col md="12">
                              <div>
                                <b-card class="text-left">
                                  <b-list-group>
                                    <div
                                      v-for="institution in selectedInstitutions"
                                      :key="institution.id"
                                    >
                                      <b-list-group-item
                                        v-on:click.once="
                                          removeInstitution(institution)
                                        "
                                        :class="{
                                          selected:
                                            institution.id ===
                                            selectedInstitution.id
                                        }"
                                        :disabled="loading"
                                        :title="institution.alternate_names"
                                        >{{ institution.full_name }}&#44;
                                        {{ institution.city }}&#44;
                                        {{ institution.state }}
                                        {{
                                          '[' + institution.id + ']'
                                        }}</b-list-group-item
                                      >
                                    </div>
                                  </b-list-group>
                                </b-card>
                              </div>
                            </b-col>
                          </b-row>
                        </b-card>
                      </div>
                      <div
                        class="d-flex justify-content-start pt-3"
                        v-if="groups !== null"
                      >
                        <span class="pr-4 my-auto">Select Group Access:</span>
                        <multiselect
                          v-model="selectedGroups"
                          :options="groups"
                          :multiple="true"
                          :close-on-select="false"
                          label="name"
                          track-by="id"
                          :showLabels="false"
                          placeholder
                          class="simple-select w-50"
                          :searchable="false"
                        >
                          <template slot="selection" slot-scope="{ values }"
                            >{{ values.length }}
                            {{ values.length | pluralize('group') }}
                            selected</template
                          >
                          <template slot="option" slot-scope="group">
                            <div>
                              <input
                                type="checkbox"
                                :checked="isGroupSelected(group.option)"
                                class="mr-1"
                              />
                              {{ group.option.name }}
                            </div>
                          </template>
                        </multiselect>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-md-7">
                          <table>
                            <tr>
                              <th width="30%">Number of FIs</th>
                              <th
                                width="20%"
                                class="text-right font-weight-normal"
                              >
                                {{ selectedInstitutions.length }}
                              </th>
                              <th width="30%"></th>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Cost / FI</td>
                              <td class="text-right">
                                {{ costPerInstitution | currencyFormat }}
                              </td>
                              <td></td>
                            </tr>
                            <tr v-if="usableCredits > 0">
                              <td class="font-weight-bold">Sub-Total</td>
                              <td class="text-right">
                                {{ totalReportCost | currencyFormat }}
                              </td>
                              <td></td>
                            </tr>
                            <tr v-if="usableCredits > 0">
                              <td class="font-weight-bold">Credits Used</td>
                              <td class="text-right">{{ usableCredits }}</td>
                              <td>of {{ availableCredits }} total</td>
                            </tr>
                            <tr>
                              <td class="font-weight-bold">Total Price</td>
                              <td class="text-right font-weight-bold">
                                {{ remaingAmountToPay | currencyFormat }}
                              </td>
                              <td></td>
                            </tr>
                          </table>
                        </div>
                        <div class="col-md-5 final-price">
                          <h2 class="mb-0" v-if="remaingAmountToPay !== null">
                            <b>${{ totalDollarAmount | numberFormat }}</b>
                            <small>
                              <sup>{{ totalCents }}</sup>
                            </small>
                          </h2>
                          <div class="buy-text">Buy and View Now</div>
                        </div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="justify-content-center list-group-container my-2">
                  <b-col md="10">
                    <b-row>
                      <b-col md="8" sm="12">
                        <b-button size variant="secondary" @click="closeModel"
                          >Close</b-button
                        >
                      </b-col>
                      <b-col md="4">
                        <b-button
                          size
                          variant="success"
                          @click="proceedToPayment"
                          :disabled="!selectedInstitutions.length"
                          >{{ order_process }}</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-list-group>
          <b-list-group
            v-else-if="paymentStage"
            :class="{ loading: loading, 'payment-stage': true }"
          >
            <b-list-group-item>
              <b-row>
                <b-col sm="8">
                  <b>
                    <i>Number of Institutions:</i>
                  </b>
                </b-col>
                <b-col sm="4" class="text-right">
                  <b>{{ selectedInstitutions.length }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <b-row>
                <b-col sm="8">
                  <b>
                    <i>Total Price:</i>
                  </b>
                </b-col>
                <b-col sm="4" class="text-right">
                  <b>{{ remaingAmountToPay | currencyFormat }}</b>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item>
              <div v-if="this.clientToken && !isPartnerAdminMock">
                <DropIn
                  wrapperClass="p-3"
                  :amount="remaingAmountToPay"
                  :authToken="clientToken"
                  :collectCardHolderName="true"
                  :enableDataCollector="true"
                  :enablePayPal="false"
                  @btError="handleError"
                  @closeModal="closeModel"
                  @btSuccess="handleSuccess"
                ></DropIn>
              </div>
              <div v-else>
                <content-placeholders>
                  <content-placeholders-heading :img="true" />
                  <content-placeholders-text :lines="3" />
                </content-placeholders>
              </div>
            </b-list-group-item>
          </b-list-group>
          <b-list-group
            v-else-if="confirmationStage"
            class="no-border confirmation-stage"
          >
            Thank you for your order. Now you can see your purchased Institution
            in Select Institution page.
            <b-list-group-item class="pt-3 text-center no-border">
              <b-row>
                <b-col cols="12">
                  <b-button size variant="secondary" @click="closeModel"
                    >Close</b-button
                  >
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </div>
    </b-card>
  </b-modal>
</template>

<script>
// global
import Vue from 'vue'
import _ from 'lodash'
import { mapState, mapGetters, mapMutations } from 'vuex'
// components
import Multiselect from 'vue-multiselect'
import DropIn from '@/views/brainTree/dropIn'
// utilities
import { BTErrorMessage } from '@/utilities'

export default {
  name: 'OrderBank',
  components: {
    DropIn,
    Multiselect
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    reportType: {
      required: true,
      type: String,
      default: 'advisor'
    }
  },
  data() {
    return {
      stage: 'summary',
      searchText: '',
      searchTextField: '',
      searchCity: null,
      searchCityField: null,
      searchState: null,
      totalReportCost: null,
      remainingFreeReports: 0,
      selectedInstitution: {},
      selectedInstitutions: [],
      institutions: [],
      states: [],
      groups: [],
      selectedGroups: [],
      loading: false,
      remaingAmountToPay: null,
      order_process: 'Purchase Now',
      fetchingData: false,
      available_credits: 0,
      usableCredits: 0,
      offset: 0,
      encryptedPrice: null
    }
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapState('Authentication', {
      mockUser: state => state.mockUser
    }),
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    isPartnerAdminMock() {
      return this.partnerSite && this.mockUser
    },
    emptyInstitutions() {
      return this.institutions.length === 0
    },
    summaryStage: function() {
      return this.stage === 'summary'
    },
    paymentStage: function() {
      return this.stage === 'payment'
    },
    confirmationStage: function() {
      return this.stage === 'confirmation'
    },
    institutionIds: function() {
      return this.institutions.map(i => i.id)
    },
    costPerInstitution: function() {
      return this.totalReportCost / this.selectedInstitutions.length
    },
    selectedInstitutionIds: function() {
      return this.selectedInstitutions.map(si => si.id)
    },
    totalDollarAmount() {
      return parseInt(this.remaingAmountToPay)
    },
    totalCents() {
      let formattedAmount = new Intl.NumberFormat('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(this.remaingAmountToPay)
      return formattedAmount.split('.')[1]
    }
  },
  methods: {
    ...mapMutations(['setStoredInstitutions', 'setRedirectUrl']),
    initInstitutions() {
      this.loading = true
      this.stage = 'summary'
      this.fetchStates()
      this.searchInstitutions().then(() => {
        this.$refs.searchInstitution.focus()
        if (this.selectedInstitutions.length) {
          this.fetchCostPerBank()
        }
      })
    },
    fetchStates: function() {
      this.$http
        .get('/api/states', {
          handleErrors: true
        })
        .then(res => {
          this.states = res.data.states
        })
    },
    searchInstitutions: function() {
      this.$wait.start('fetchingFIs')
      this.offset = 0
      this.selectedInstitution = {}
      return this.$http
        .get('/api/users/new_order', {
          params: {
            search_text: this.searchText,
            city: this.searchCity,
            state: this.searchState
          },
          handleErrors: true
        })
        .then(res => {
          this.institutions = res.data.financial_institutions
          this.groups = res.data.groups
          this.selectedGroups = this.groups || []
          this.remainingFreeReports = res.data.available_free_reports || 0
        })
        .then(() => {
          this.loading = false
          this.$wait.end('fetchingFIs')
        })
    },
    fetchMoreData(event) {
      let el = event.target
      if (Math.round(el.scrollTop) >= el.scrollHeight - el.offsetHeight) {
        this.fetchAdditionalInstitutions()
      }
    },
    fetchAdditionalInstitutions() {
      if (this.offset < this.institutions.length) {
        this.loading = true
        this.offset = this.institutions.length
        this.$http
          .get('/api/users/new_order', {
            params: {
              search_text: this.searchText,
              city: this.searchCity,
              state: this.searchState,
              offset: this.offset
            },
            handleErrors: true
          })
          .then(res => {
            this.institutions.push(...res.data.financial_institutions)
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    isGroupSelected(option) {
      return this.selectedGroups.map(ag => ag.name).includes(option.name)
    },
    search: _.debounce(function() {
      this.searchInstitutions()
    }, 500),

    setInstitution: function(institution) {
      this.selectedInstitution = institution
      if (!this.selectedInstitutionIds.includes(institution.id)) {
        this.selectedInstitutions.unshift(institution)
        this.fetchCostPerBank()
      }
    },
    removeInstitution: function(institution) {
      this.selectedInstitutions.splice(
        this.selectedInstitutions.indexOf(institution),
        1
      )
      this.fetchCostPerBank()
    },
    closeModel: function() {
      this.selectedInstitutions = []
      this.searchText = ''
      this.searchTextField = ''
      this.searchCity = null
      this.searchCityField = null
      this.searchState = null
      this.setStoredInstitutions([])
      this.$emit('closeModal')
      this.stage = 'summary'
    },
    proceedToPayment: function() {
      if (this.currentUserEmail) {
        this.loading = true
        this.$http
          .post(
            '/api/checkouts',
            {
              record_count: this.selectedInstitutions.length,
              bank_ids: this.selectedInstitutions.map(bank => bank.id),
              group_ids: this.selectedGroups.map(group => group.id),
              amount: this.remaingAmountToPay,
              encrypted_price: this.encryptedPrice
            },
            { handleErrors: true }
          )
          .then(res => {
            this.loading = false
            this.setStoredInstitutions([])
            if (res.data.ordered) {
              this.stage = 'confirmation'
              this.$emit('institutionUpdated', res.data.banks)
            } else {
              this.clientToken = res.data.client_token
              this.orderId = res.data.order_id
              this.stage = 'payment'
            }
          })
      } else {
        this.setStoredInstitutions(this.selectedInstitutions)
        this.$toasted.show(
          'You are not logged in, please login and continue.',
          { icon: 'chain-broken', type: 'error' }
        )
        this.$emit('openAuthModal')
      }
    },
    handleError: function() {},
    handleSuccess: function(payload) {
      let data = new window.FormData()
      data.append('order_id', this.orderId)
      data.append('payment_method_nonce', payload.nonce)
      this.loading = true
      this.$http
        .post('/api/checkouts/complete', data, {
          handleErrors: false
        })
        .then(
          res => {
            this.loading = false
            this.$ahoy.track('fi_order', {
              fi_ids: this.selectedInstitutionIds
            })
            if (res.data.order_success) {
              this.stage = 'confirmation'
              this.$emit('institutionUpdated', res.data.banks)
            } else {
              this.stage = 'summary'
              this.$toasted.show(BTErrorMessage, {
                icon: 'chain-broken',
                type: 'error'
              })
            }
          },
          () => {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        )
    },
    fetchCostPerBank() {
      if (this.selectedInstitutions.length) {
        this.loading = true
        this.$wait.start('fetchingCost')
        this.$http
          .get('/api/advisors/price', {
            params: {
              type: this.reportType,
              fi: this.selectedInstitutions.map(fi => fi.id)
            }
          })
          .then(response => {
            this.totalReportCost = response.data.total_price
            this.remaingAmountToPay = response.data.pay_price
            this.availableCredits = response.data.available_credits
            this.usableCredits = response.data.usable_credits
            this.encryptedPrice = response.data.encrypted_price
          })
          .then(() => {
            this.loading = false
            this.$wait.end('fetchingCost')
          })
      }
    }
  },
  watch: {
    searchTextField(newVal) {
      if (newVal !== this.searchText) {
        this.searchText = newVal
        this.search()
      }
    },
    searchCityField(newVal) {
      if (newVal !== this.searchCity) {
        this.searchCity = newVal
        this.search()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-result,
.selected-result,
.search-bar,
.order-summary {
  font-size: 0.9em;
}
.list-group-container {
  padding-top: 1em;
  .no-padding {
    padding: 0;
  }
  .full-border {
    border: 1px solid lightgray;
  }
}
.filter-result,
.selected-result {
  min-height: 10rem;
  max-height: 10rem;
  overflow-y: scroll;
  .card {
    .card-body {
      padding: 0.5rem;
      overflow-x: hidden;
      .list-group-item {
        padding: 0.25em 1em;
        border: none;
        cursor: pointer;
        &.selected {
          background-color: #f0f3f5;
        }
        &:hover {
          background-color: #f0f3f5;
        }
      }
      .list-group-item.disabled {
        cursor: not-allowed;
      }
    }
  }
}
.card-sub-header {
  margin-top: 1em;
  font-weight: bold;
}
.selected-result {
  border: 1px solid #d1d4d7;
  .card {
    margin-top: 0;
  }
}
.filter-result {
  .card {
    margin-bottom: 0;
  }
}
.order-summary {
  &.card {
    padding-bottom: 0;
  }
  > .card-body {
    padding-top: 0;
    padding-bottom: 0;
    height: 4rem;
  }
}
.list-group-item {
  &.no-border {
    border: none;
  }
}
.order-institute-card {
  padding: 0.7em;
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
.final-price {
  text-align: right;
  padding-right: 5.5rem;
  .amount {
    font-size: xx-large;
  }
  .buy-text {
    font-size: medium;
  }
}
</style>
